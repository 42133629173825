
let animate = {
    $term1: undefined,
    $term2: undefined,
    $term3: undefined,
    $term4: undefined,
    $term5: undefined,
    flipInOut1(){
        setTimeout(function(){
            animate.$term1.removeClass('invisible').addClass('flipInX animated');
        },0);
        setTimeout(function(){
            animate.$term1.not('.js-term-last').removeClass('flipInX animated').addClass('flipOutX');
        },1000);
        setTimeout(function(){
            animate.$term1.not('.js-term-last').removeClass('flipOutX').addClass('invisible');
        },1400);

    },
    flipInOut2(){
        setTimeout(function(){
            animate.$term2.removeClass('invisible').addClass('flipInX animated');
        },1400);
        setTimeout(function(){
            animate.$term2.not('.js-term-last').removeClass('flipInX animated').addClass('flipOutX');
        },2400);
        setTimeout(function(){
            animate.$term2.not('.js-term-last').removeClass('flipOutX').addClass('invisible');
        },2800);
    },
    flipInOut3(){
        setTimeout(function(){
            animate.$term3.removeClass('invisible').addClass('flipInX animated');
        },2800);
        setTimeout(function(){
            animate.$term3.not('.js-term-last').removeClass('flipInX animated').addClass('flipOutX');
        },3800);
        setTimeout(function(){
            animate.$term3.not('.js-term-last').removeClass('flipOutX').addClass('invisible');
        },4200);
    },
    flipInOut4(){
        setTimeout(function(){
            animate.$term4.removeClass('invisible').addClass('flipInX animated');
        },4200);
        setTimeout(function(){
            animate.$term4.not('.js-term-last').removeClass('flipInX animated').addClass('flipOutX');
        },5200);
        setTimeout(function(){
            animate.$term4.not('.js-term-last').removeClass('flipOutX').addClass('invisible');
        },5600);
    },
    flipInOut5(){
        setTimeout(function(){
            animate.$term5.removeClass('invisible').addClass('flipInX animated');
        },5600);
        setTimeout(function(){
            animate.$term5.not('.js-term-last').removeClass('flipInX animated').addClass('flipOutX');
        },6600);
        setTimeout(function(){
            animate.$term5.not('.js-term-last').removeClass('flipOutX').addClass('invisible');
        },7000);
    },
}
export default function(){
    animate.flipInOut1();
    animate.flipInOut2();
    animate.flipInOut3();
    animate.flipInOut4();
    animate.flipInOut5();
    animate.$term1 = $('.js-term-1');
    animate.$term2 = $('.js-term-2');
    animate.$term3 = $('.js-term-3');
    animate.$term4 = $('.js-term-4');
    animate.$term5 = $('.js-term-5');
}
