let scrollTitle = {
    $title: undefined,
    scrollInit(){
        $(document).load(function(){
            if( (scrollTitle.$maxDistance - $(document).scrollTop()) < 55 ){
                scrollTitle.$title.css('top', scrollTitle.$maxDistance - $(document).scrollTop() - 48);
            }
        }); //on page load, scrolls the fixed title when it is 48px from the footer
        $(document).scroll(function(){
            if( (scrollTitle.$maxDistance - $(document).scrollTop()) < 55 ){
                scrollTitle.$title.css('top', scrollTitle.$maxDistance - $(document).scrollTop() - 48);
            }else{
                scrollTitle.$title.css('top', '0');
            }
        }); //on scroll, scrolls the fixed title when it is 48px from the footer
    }
}
export default function(){
    scrollTitle.$title = $('.js-scroll-title');
    scrollTitle.$footer = $('.js-scroll-footer');
    scrollTitle.$maxDistance = scrollTitle.$footer.offset().top - scrollTitle.$title.outerHeight();
    scrollTitle.$distanceScrolled = scrollTitle.$maxDistance - $(document).scrollTop();
    scrollTitle.scrollInit();
}
