import lightbox2 from "lightbox2";
export default function(){
    lightbox2.option({
        'fadeDuration' : 200,
        'positionFromTop' : 50,
        'resizeDuration' : 200,
        'disableScrolling' : true,
    });
    $('a>img').parent('a').each(function(){
        if( $(this).attr('href').indexOf('uploads') > -1 ){
            $(this).attr('data-lightbox','gallery');
        }
    });
}
