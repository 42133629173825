import fitvids from "fitvids";
import toTop from "./to-top";
import fontawesome from "./vendor/fontawesome";
import modernizr from "./vendor/modernizr-custom";
import touch from "./touch";
import navigation from "./navigation";
import accordion from "./accordion";
import flexSlider from "flexslider";
import carousel from "./content-carousel";
import scrollTitle from "./scroll-title";
import frontPageHeader from "./front-page-header";
import lightbox from "./lightbox";

window.jQuery = window.$ = jQuery;

jQuery(
    (function($) {
        $(document).ready(() => {
            /**
             * Setup Font Awesome icons
             * @see https://fontawesome.com/icons
             */
            fontawesome();

            /**
             * Setup Fivids
             * @see https://www.npmjs.com/package/fitvids
             */
            fitvids(".js-fitvid");

            /**
             * Setup back to top
             */
            toTop();

            /**
             * Setup navigation
             */
            navigation();

            /**
             * Setup accordions
             */
            accordion();

             /**
             * Setup modernizr for touch events
             */
            modernizr();

            /**
             * Setup modernizr for touch events
             */
            touch();

             /**
             * Setup flexslider and the content carousel module
             */
            carousel();

            /**
             * Scroll title on news/case study
             */
            scrollTitle();

              /**
             * Front page header animation
             */
            frontPageHeader();

            /**
             * Lightbox options
             */
            lightbox();
        });
    })(jQuery)
);
