export default function(){
    $(document).ready(function(){
        $('.js-carousel').each(function(){
            let suffix = $(this).attr('data-suffix');
            $(this).flexslider({
                animation: "fade",
                controlNav: true,
                directionNav: false,
                manualControls: '.js-carousel-nav-' + suffix + ' li',
            });
        });
    });
}
